   

window.addEventListener('load', (event) => {

    const header = document.querySelector("header")
    const body = document.querySelector("body")

    if (header.matches('.menu-red')) {
        body.classList.add('red');
    }
    else if (header.matches('.menu-yellow-no-graphics')) {
        body.classList.add('yellow-no-graphics');
    } else {
        body.classList.add('yellow');
    }
});


