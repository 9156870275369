const partnerListCompactSection = document.getElementsByClassName('partners');

if(partnerListCompactSection.length > 0) {
    const partnerName = partnerListCompactSection[0].getElementsByClassName('partner-row');
    const partnerImages = partnerListCompactSection[0].getElementsByClassName('partner-image');


    var screenSizeBig = true;
    var screenWidth = $(window).width();

    $(window).on("load", function() {
        screenWidth = $(window).width();
        if ($(window).width() >= 768){
            screenSizeBig = true;
        } else {
            screenSizeBig = false;
        }
    })

    $(window).on("resize", function() {
        screenWidth = $(window).width();
        if ($(window).width() >= 768){
            screenSizeBig = true;
        } else {
            screenSizeBig = false;
        }
    })

    for (let i = 0; i < partnerName.length; i++) {
        partnerListCompactSection[0].addEventListener('mousemove', function(event) {
            moveImage(event, i);
        });

        partnerName[i].addEventListener('mouseenter', function(event) {
            showImage(i);
        });

        partnerName[i].addEventListener('mouseleave', function(event) {
            hideImage(i);
        });
    }


    function moveImage(event, elementNumber) {
        if (screenSizeBig) {
            figureWithImage = partnerImages[elementNumber];
            var x = event.pageX;
            x = x+20;
            var y = event.pageY;
            y = y+20;

            if(x<screenWidth-320){
                figureWithImage.style.left = x +'px';
            }
            figureWithImage.style.top = y +'px';
        }
    }

    function showImage(elementNumber){
        if (screenSizeBig) {
            figureWithImage = partnerImages[elementNumber];
            figureWithImage.classList.add("partner-image-onhover");
        }
    }

    function hideImage(elementNumber){
        if (screenSizeBig) {
            figureWithImage = partnerImages[elementNumber];
            figureWithImage.classList.remove("partner-image-onhover");
        }
    }
    }