window.addEventListener("load", (event) => {
  const button = document.querySelectorAll(".accrdion-item");
  const accordionContent = document.querySelectorAll(".accrdion-content");

  if(button){
    button.forEach((element, index) => {
      if(element.classList.contains('accordion-link')){
        return
      }
      element.addEventListener("click", function() {
        accordionContent[index].classList.toggle("show");
        element.classList.toggle("active");
        
        if(accordionContent[index].classList.contains("show")) {
          accordionContent[index].style.maxHeight = accordionContent[index].scrollHeight+'px';
        } else {
          accordionContent[index].style.maxHeight = '0px';
        }
      });
    });
  }
});
/*
  firstButton.click(function (e) {
    id = this.id;

    list = document.getElementsByClassName(id)[0];
    console.log(document.getElementsByClassName(id));
    
    if (!this.classList.contains("active")) {
      button.forEach((element) => {
        console.log(element);
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });
      console.log(" ");
      e.currentTarget.classList.add("active");

      filesWrapper.forEach((element) => {
        console.log(element);
        if (element.classList.contains("show")) {
          element.classList.remove("show");
      
          setTimeout(function () {
            element.classList.remove("display");
          }, 300);
        } else {
          list.classList.add("display");
          setTimeout(function () {
            list.classList.add("show");
          }, 300);
        }
      });
      console.log(" ");
    } else {
      e.currentTarget.classList.remove("active");

      filesWrapper.forEach((element) => {
        console.log(element);
        if (element.classList.contains("show")) {
     
          element.classList.remove("show");
          setTimeout(function () {
            element.classList.remove("display");
          }, 300);
        }
      });
      console.log(" ");
    }
  });*/
