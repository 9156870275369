const $news = $('.news-archive .the-rest article.tease');
const $moreButton = $('.news-archive').find('a.more-news');

let numberOfHighlightArticles = 3; // The number of highlight articles on news page. Used to sum up total articles shown. 
let offset = 7; //Controls the offset on which articles to load. 3 highlight + 4 normal articles show on load.

if($news.length > 0) {
    let $totalPosts = $('#posts-total').data('total');

    $moreButton.click(function(e) {
        e.preventDefault();        
        let data = new FormData();
        data.append('action', 'get_news');
        data.append('offset', offset);
        $.ajax({
            type: 'POST',
            url: ranglerock_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                var appendedNews = res;
                $('.news-archive .the-rest').append(appendedNews);
                let $totalPosts = $('.news-archive #posts-total').data('total');
                
                if (($('.news-archive .the-rest article.tease').length)+numberOfHighlightArticles < $totalPosts) {
                    $moreButton.removeClass('hide');
                }
                else {
                    $moreButton.addClass('hide');
                }
            }
        });
        offset = offset+4; //Increase the offset by the numbers of articles fetch per page.
    });  
}