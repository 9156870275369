// hamburger nav


window.addEventListener('load', (event) => {

    const button = document.querySelector(".menu-button")
    const header = document.querySelector("header")

    const buttonText = document.querySelector(".button-text")
    let body = document.querySelector("body");

    if(button){
        button.onclick = function toggleMenu() {
            if (header.matches('.show')) {
                header.classList.remove('display');
                header.classList.remove('show');
                button.classList.remove("menu-close");
                body.classList.remove("fixed-position")
            }
            else {
                button.classList.add("menu-close");
                header.classList.add('display');
                header.classList.add('show');
                body.classList.add("fixed-position")
            }
        };
    }
});


