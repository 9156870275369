  let menu = $('header')
  let body = $('body');
  let heroImage = $('.hero figure');
  let whiteText = false;

  if( $('body.home').length ){
    window.addEventListener("scroll", homeMenu);
  }
  else{
    menu.addClass('show-logo');
  }

  if(heroImage && heroImage.length > 0) {
      menu.addClass('hide-logo');
      whiteText = true;
  }


function homeMenu() {
    if (menu) {
        if(window.scrollY > 16 && window.innerWidth < 1440) {
            if (window.pageYOffset >= 200) {
                if(whiteText) {
                    menu.removeClass('hide-logo');
                    menu.addClass('show-logo');
                }
            } else if (window.pageYOffset < 200) {
                if(whiteText) {
                    menu.addClass('hide-logo');
                    menu.removeClass('show-logo');
                }
            }
        }
        else {
            if (window.pageYOffset >= 120) {
                if(whiteText) {
                    menu.removeClass('hide-logo');
                    menu.addClass('show-logo');
                }
            } else if (window.pageYOffset < 120) {
                if(whiteText) {
                    menu.addClass('hide-logo');
                    menu.removeClass('show-logo');
                }
            }
        }

    }
}